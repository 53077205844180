@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.less";
@import "./styles/colors.less";
@import "./styles/global.less";

* {
  font-family: "Inter", sans-serif;
}

img {
  display: unset;
}

@primary-color: #4252CF;