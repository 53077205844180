::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(90deg, #5c72f4 0%, #404fcc 100%);
}

.bg-gradient {
  background: linear-gradient(90deg, #5c72f4 0%, #404fcc 100%);
}

.card {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(55, 54, 53, 0.06);
  border-radius: 12px;
}

.ctn {
  background-color: var(--background);
}

.autocomplete {
  input {
    height: 100% !important;
  }
}

.page-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  font-feature-settings: "ss01" on, "cv05" on, "cv06" on, "cv08" on, "cv10" on,
    "cv11" on, "kern" off;
  color: #0f172a;
}

.page-subtitle {
  font-size: 14px;
  line-height: 22px;
  color: #000000;
}

.upload-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #e2e4e9;
  border-radius: 13px;
  cursor: pointer;
}

.divider {
  width: 100%;
  background-color: #e2e4e9;
  height: 1px;
  margin: 24px 0;
}

.card__custom {
  background-color: white;
  border-radius: 12px;
  padding: 29px;
}

.square__image__rdu {
  width: 34px;
  height: 34px;
  border-radius: 4px;
  object-fit: cover;
  min-width: 34px;
}

.rounded__image {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  object-fit: cover;
  min-width: 34px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  padding: 0 16px;
  height: 45px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  transition: border-color 0.25s ease-in-out;
}

.ant-select-selection-item,
.ant-select-selection-placeholder {
  display: flex;
  align-items: center;
}

.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
  background: #ffffff;
  border: 1px solid #dadadd;
  border-radius: 16px;
  padding: 0;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f6;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  font-feature-settings: "salt" on;
  color: #000000;
}

.ant-select-item-option-active {
  background-color: #f8f8f8;
}

.ant-select-item-option {
  padding: 11.5px;
}
