.sidebar {
  width: 285px;
  background-color: #1b1b1c;

  .sidebar-list {
    background: transparent;
    color: #969ba8;

    // > li {
    //   > div {
    //     > .ant-menu-submenu-arrow {
    //       color: #969ba8;
    //     }
    //   }
    //   &.ant-menu-submenu-open {
    //     background: white;
    //     color: #1d1c2d;

    //     > ul {
    //       background-color: #1b1b1c;
    //       > div {
    //         > span {
    //           color: #1d1c2d;
    //         }
    //       }
    //       > .ant-menu-item {
    //         background: #1b1b1c;
    //         color: #909098;
    //       }
    //       > .ant-menu-item-selected {
    //         color: #ffffff;
    //       }
    //     }
    //   }
    // }
  }

  .mini-profile {
    position: relative;
    background-color: rgba(#969ba8, 0.15);
    border-radius: 12px;
    color: white;

    .name {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
    }

    .email {
      font-size: 12px;
      line-height: 17px;
      color: #969ba8;
    }
  }
}

.ant-menu-submenu-selected .ant-menu-submenu-title {
  .ant-menu-item-icon {
    fill: white;
  }

  .ant-menu-title-content {
    color: white;
  }

  .ant-menu-submenu-arrow {
    color: white;
  }
}

.ant-popover-arrow-content::before {
  background-color: #4b4b59;
}
.ant-popover-inner-content {
  background-color: #4b4b59;
  color: #fff;
  cursor: pointer;

  .menu-item {
    display: flex;
    gap: 0 12px;
  }
}

.ant-menu-inline {
  border-right: none;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  display: none;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: white;
}

.ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content {
  color: #909098;
}

.ant-menu-inline.ant-menu-root {
  .ant-menu-item-selected {
    .ant-menu-item-icon {
      fill: #1d1c2d !important;
    }

    .ant-menu-title-content {
      color: #1d1c2d;
      font-weight: 500;
    }
  }
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  background-color: #1b1b1c;
  margin: 0;
}

.ant-menu-submenu.ant-menu-submenu-open {
  // background-color: white;

  // .ant-menu-title-content,
  // i {
  //   color: white;
  // }

  .ant-menu-item-selected {
    background-color: white;
    .ant-menu-title-content {
      color: #1d1c2d;
    }
  }
}

.ant-menu-submenu-title i {
  color: inherit;
}

.ant-menu-light .ant-menu-submenu-title:hover,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: white;
}

.ant-menu-submenu-title:active {
  background-color: transparent;
}

@primary-color: #4252CF;